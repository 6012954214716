import React from "react";
import AvatarImage from "../../components/AvatarImage";
import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

const LeadsUser = (props) => {
  const { user, color } = props;

  return (
    <UserContainer container alignItems="center">
      <Grid item className="avatar-container">
        <AvatarImage width={130} height={130} name={user.name} src={user.image} />
      </Grid>
      <Grid item xs={12} sm>
        <Grid container alignItems="center">
          <Description container color={color}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h4" className="bold">
                  {user.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">{user.email}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">{user.phone}</Typography>
              </Grid>
            </Grid>
          </Description>
        </Grid>
      </Grid>
    </UserContainer>
  );
};

const UserContainer = styled(Grid)`
  .avatar-container {
    padding-right: 25px;

    @media (max-width: 600px) {
      padding: 15px 0px 15px;
    }
  }
`;

const Description = styled(Grid)`
  h4,
  h6 {
    color: ${(p) => (p.color ? p.color : "white")};
  }

  .bold {
    font-weight: 700;
  }
`;

export default LeadsUser;
