const COMPANY_NAME = "GRUPO RE/MAX DRAGÃO";

export const COPY = {
  languages: ["pt", "en"],
  pt: {
    agent: "O seu consultor:",
    agency: "A sua agência:",
    content: {
      title: "GRUPO IMOBILIÁRIO Nº1 NO NORTE",
      subTitle: "AVALIE O SEU IMÓVEL EM SEGUNDOS",
    },
    disclaimer: `A utilização desta plataforma é gratuita e pretende fornecer um valor expetável de venda de um imóvel determinado, tendo por base uma análise estatística. Os resultados gerados são estimativas e fornecem valores meramente indicativos, obtidos a partir da análise estatística de bases de dados de imóveis e outros indicadores de mercado imobiliário. Como tal, o Grupo ${COMPANY_NAME} não garante qualquer tipo de compensação caso não sejam alcançados os valores estimados. Caso pretenda uma avaliação certificada, deverá contactar um Perito Avaliador certificado pela CMVM - Comissão de Mercado de Valores Mobiliários. Ao subscrever os serviços disponibilizados por esta plataforma, e mediante o seu consentimento, o Grupo ${COMPANY_NAME} procede à recolha e ao tratamento dos dados pessoais necessários à prestação dos serviços de mediação imobiliária e a fornecer informação sobre bens imobiliários e oportunidades de negócios, designadamente através de ações de informação e marketing. O Grupo ${COMPANY_NAME} irá transmitir os dados recolhidos aos consultores imobiliários que compõem a sua rede, exclusivamente para os fins mencionados. Nos termos da legislação em vigor, a nossa empresa compromete-se a garantir a proteção, a confidencialidade e a segurança dos dados pessoais fornecidos. Os titulares dos dados podem a todo o tempo, aceder e/ou solicitar a retificação ou eliminação dos seus dados pessoais, devendo para o efeito dirigir o pedido por escrito através do endereço de correio eletrónico grupodragao@remax.pt. Os dados ora recolhidos serão conservados pelo período de tempo estritamente necessário ao cumprimento dos fins que justificam a sua recolha e apenas pelo período de duração do registo do Subscritor.`,
  },
  en: {
    agent: "Your agent:",
    agency: "Your agency:",
    content: {
      title: "REAL ESTATE NUMBER ONE GROUP IN THE NORTH OF PORTUGAL",
      subTitle: "ESTIMATE YOUR ASSET MARKET VALUE",
    },
    disclaimer: `The use of this platform is free and aims to provide an expected sale value of a specific property, based on a statistical analysis. The results generated are estimates and provide merely indicative values, obtained from the statistical analysis of real estate databases and other real estate market indicators. As such, the Group ${COMPANY_NAME} does not guarantee any compensation if the estimated amounts are not reached. If you want a certified appraisal, you should contact an Appraisal Expert certified by the CMVM - Securities Market Commission. By subscribing to the services provided by this platform, and with your consent, the ${COMPANY_NAME} Group collects and processes the personal data necessary to provide real estate services and to provide information on real estate and business opportunities, namely through information and marketing actions. The ${COMPANY_NAME} Group will transmit the collected data to the real estate consultants that make up its network, exclusively for the purposes mentioned. Under current legislation, our company is committed to ensuring the protection, confidentiality and security of the personal data provided. Data holders may, at any time, access and / or request the rectification or deletion of their personal data, and for this purpose they must address the request in writing through the email address grupodragao@remax.pt. The data now collected will be kept for the period of time strictly necessary to fulfill the purposes that justify its collection and only for the duration of the Subscriber's registration.`,
  },
};
