import React from "react";
import { alfredoAPIRequesterV2 } from "../../exporters";
import { Button, Grid } from "@material-ui/core";
import styled from "styled-components";

const Footer = (props) => {
  const {
    setPerformingAction,
    performingAction,
    smsValidation,
    //handleClose,
    setSubmited,
    setLoading,
    setLeadID,
    setFields,
    submited,
    setStep,
    userID,
    leadID,
    fields,
    mobile,
    token,
    step,
    copy,
  } = props;

  const validateFields = () => {
    let updateFields = [...fields[step]];
    updateFields.forEach((field, idx) => {
      if (field.validator && !field.error) {
        const validated = field.validator(field.value);
        updateFields[idx].value = validated.value;
        updateFields[idx].error = validated.error || false;
        updateFields[idx].helperText = validated.helperText.trim() || null;
      }
    });
    setFields({ ...fields });

    return updateFields.every((field) => !field.error);
  };

  const handleContinue = () => {
    const advance = validateFields();
    if (!advance) return;
    setStep(step + 1);
  };

  const handleSubmit = () => {
    const advance = validateFields();
    if (!advance) return;

    setPerformingAction(true);
    setLoading(true);

    const _fields = {};
    const characteristics = [];

    fields[0].forEach((f) => {
      const isNull = f.value == null || f.value.length === 0;
      if (!isNull) {
        if (f.type === "number") {
          _fields[f.id] = parseFloat(f.value);
        } else {
          _fields[f.id] = f.value;
        }
      }
    });

    fields[1].forEach((f) => {
      if (f.value) {
        characteristics.push(f.id);
      }
    });

    const params = {
      fields: _fields,
      characteristics,
      client: {
        name: fields[2][0].value,
        email: fields[2][1].value,
        phone: fields[2][2].value.replace("+", ""),
        type: fields[2][3].value,
      },
      realtor: userID,
    };

    /*     const params = {
      fields: {
        address: "Douro Royal Valley Hotel & Spa, Baião, Portugal",
        assetType: "apartment",
        condition: "new",
        energyEfficiency: "B",
        grossArea: 200,
        latitude: 41.1033304,
        longitude: -8.0946714,
        numberOfRooms: 1,
      },
      characteristics: [],
      client: {
        name: "Mário Gamas",
        email: "mario.gamas@alfredo.pt",
        phone: "913984046",
        gender: "",
        type: "buy",
      },
      realtor: userID,
    }; */

    alfredoAPIRequesterV2
      .post({
        urlPath: "leads",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((res) => {
        if (!smsValidation) {
          setSubmited({ status: true, success: true, predict: res.predict });
        } else {
          setLeadID(res.id);
        }
      })
      .catch((e) => {
        setSubmited({ status: true, success: false, predict: {} });
      })
      .finally(() => {
        // if (smsValidation) setStep(step + 1);
        setStep(step + 1);
        setPerformingAction(false);
        setLoading(false);
      });
  };

  const handleConfirm = () => {
    const advance = validateFields();
    if (!advance) return;

    setPerformingAction(true);
    setLoading(true);

    const params = {
      code: fields[3][0].value.replace(/\s/g, "").replace(/_/g, ""),
      id: leadID,
    };

    alfredoAPIRequesterV2
      .post({
        urlPath: "leads/validate_sms",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      })
      .then((res) => {
        setSubmited({ status: true, success: true, predict: res.predict });
      })
      .catch((e) => {
        setSubmited({ status: true, success: false, predict: {} });
      })
      .finally(() => {
        setPerformingAction(false);
        setLoading(false);
        setLeadID(null);
      });
  };

  return (
    <Container container mobile={mobile}>
      {!submited.status ? (
        <Grid item xs={12} className="inner-container">
          {step < 2 && (
            <Button
              className="vlt-btn vlt-btn--primary vlt-btn--md"
              onClick={handleContinue}
              variant="contained"
              disableFocusRipple
              disableElevation
              color="secondary"
              fullWidth
            >
              {copy.continue}
            </Button>
          )}
          {step === 2 && (
            <Button
              className="vlt-btn vlt-btn--primary vlt-btn--md"
              disabled={performingAction}
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
              disableElevation
              fullWidth
            >
              {copy.submit}
            </Button>
          )}
          {step === 3 && (
            <Button
              className="vlt-btn vlt-btn--primary vlt-btn--md"
              disabled={performingAction}
              onClick={handleConfirm}
              variant="contained"
              color="secondary"
              disableElevation
              fullWidth
            >
              {copy.confirm}
            </Button>
          )}
        </Grid>
      ) : (
        <Grid item xs={12} className="inner-container">
          {/*   <Button
            className="vlt-btn vlt-btn--primary vlt-btn--md"
            disabled={performingAction}
            onClick={() => window.location.reload()}
            variant="contained"
            color="secondary"
            disableElevation
            fullWidth
          >
            {copy.reload}
          </Button> */}
        </Grid>
      )}
    </Container>
  );
};

const Container = styled(Grid)`
  background: linear-gradient(180deg, transparent 30%, rgba(255, 255, 255, 1) 100%);
  // position: ${(p) => (p.mobile ? "fixed" : "relative")};
  bottom: 0px;
  width: 100%;

  .inner-container {
    padding: 15px 10px 10px 10px;
    z-index: 99;
  }
`;

export default Footer;
