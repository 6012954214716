import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";

import ukFlag from "../../assets/images/svgs/gb.svg";
import ptFlag from "../../assets/images/svgs/pt.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    position: "absolute",
    top: 0,
    right: 0,
    padding: "25px 2.5%",
  },
  menuItem: {
    cursor: "pointer",
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: 13,
    opacity: 1,
    padding: "0px 25px",
    "&:hover": {
      opacity: 1,
    },
  },
  icon: {
    width: 30,
    paddingRight: 5,
    verticalAlign: "middle",
  },
}));

const SelectLanguage = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleChange = (lang) => () => {
    props.changeLanguage(lang)();
    handleMenuClose();
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  let { language, color } = props;

  return (
    <div className={classes.root}>
      <Grid
        item
        onClick={handleProfileMenuOpen}
        className={classes.menuItem}
        style={{ color: color ? color : "white" }}
      >
        {language}
      </Grid>
      <Menu
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        id="language-menu"
        open={isMenuOpen}
        keepMounted
      >
        <MenuItem onClick={handleChange("pt")} value={"pt"}>
          <img className={classes.icon} src={ptFlag} alt="pt-lang" key="pt" />
          PT
        </MenuItem>
        <MenuItem onClick={handleChange("en")} value={"en"}>
          <img className={classes.icon} src={ukFlag} alt="en-lang" key="en" />
          EN
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SelectLanguage;
