const styles = (theme) => {
  return {
    container: {},
    avatarContainer: {
      padding: 25,
    },
    avatar: {
      fontSize: "20px",
    },
    avatarPointer: {
      cursor: "pointer",
      fontSize: "100%",
    },
    customButton: {
      backgroundColor: "white",
      color: "#009DE0",
      /*fontFamily: "Open Sans"*/
      padding: "5px 15px",
      fontSize: "14px",
      borderRadius: "3px",
      "&:hover": {
        cursor: "pointer",
        color: "white",
        backgroundColor: "#009DE0",
        transition: "background-color 500ms ease, color 500ms ease",
      },
      [theme.breakpoints.only("xs")]: {
        margin: "15px 0",
      },
    },
    loadingBadge: {
      top: "50%",
      right: "50%",
    },
  };
};

export default styles;
