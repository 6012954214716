export const LEADS_FORM = {
  pt: {
    estimates: "Valores estimados",
    soon: "Obrigado pela preferência. Será contactado mais tarde com mais informações.",
    success: "Email enviado! Por favor consulte a sua caixa de correio.",
    fail: "Limite semanal atingido, por favor novamente na próxima semana.",
    exceededLimit: "Limite excedido, por favor entre em contacto",
    close: "Close",
    reload: "Tentar novamente",
    header: {
      0: "Saiba o valor do seu imóvel gratuitamente",
      1: "Características",
      2: "Dados pesssoais",
      3: "Resultados",
      //3: "Validação SMS",
    },
    step: "Passo",
    of: "de",
    continue: "CONTINUAR",
    submit: "SUBMETER",
    confirm: "CONFIRMAR",
    invalidLatLng: "A morada introduzida tem que ser em Portugal",
    interestedIn: "Interessado em:",
    enterCode: "Introduza o código enviado para",
    labels: {
      email: "O seu email",
      assetType: "Tipo de imóvel",
      apartment: "Apartamento",
      house: "Moradia",
      room: "Quarto",
      building: "Prédio",
      office: "Escritório",
      store: "Loja",
      warehouse: "Armazém",
      hotel: "Hotel",
      hostel: "Hostel",
      mansion: "Mansão",
      palace: "Palácio",
      homestead: "Quinta",
      commercial: "Comercial",
      storageRoom: "Arrecadação",
      parkingSpace: "Garagem",
      land: "Terreno",
      urbanLand: "Terreno Urbano",
      buildingYear: "Ano de construção",
      condition: "Condição",
      energyEfficiency: "Cert. energético",
      grossArea: "Área bruta",
      numberOfParkingSpots: "Lugares estacionamento",
      numberOfRooms: "Tipologia",
      numberOfWcs: "Casas de banho",
      terrainArea: "Área de terreno",
      usefulArea: "Área útil",
      wardrobe: "Roupeiro embutido",
      gym: "Ginásio",
      airConditioning: "Ar condicionado",
      alarm: "Alarme",
      cityView: "Vista de cidade",
      closedGarage: "Garagem",
      pool: "Piscina",
      balcony: "Varanda",
      elevator: "Elevador",
      storage: "Arrecadação",
      electricBlinds: "Estores eléctricos",
      parking: "Estacionamento",
      equippedKitchen: "Cozinha equipada",
      videoSecurity: "Video-porteiro",
      fireplace: "Lareira",
      garden: "Jardim",
      centralHeating: "Aquecimento central",
      suite: "Suite",
      pantry: "Despensa",
      doubleWindows: "Vidros duplos",
      goodLightExposure: "Exposição solar",
      basement: "Cave",
      terrace: "Terraço",
      seaView: "Vista de mar",
      name: "Nome",
      phone: "Número de telefone",
      leadType: "Eu sou",
    },
    options: {
      assetType: {
        apartment: "Apartamento",
        house: "Moradia",
      },
      condition: {
        new: "Novo",
        used: "Usado",
        renovated: "Renovado",
        inConstruction: "Em construção",
        forRecovery: "Para recuperação",
        ruin: "Em ruína",
        almostNew: "Como novo",
      },
      numberOfRooms: {
        0: "T0",
        1: "T1",
        2: "T2",
        3: "T3",
        4: "T4",
        5: "T5",
        6: "T6",
        7: "T7",
        8: "T8",
        9: "T9",
        10: "T10",
        11: "T11",
        12: "T12",
        13: "T13",
      },
      leadType: {
        buy: "Comprar",
        sell: "Vender",
        rent: "Arrendar",
      },
    },
  },
  en: {
    estimates: "Estimated values",
    soon: "You will be contacted later with more information.",
    success: "Email sent! Please check your inbox",
    fail: "Weekly limit reached, please try again next week",
    exceededLimit: "Limited exceeded, please get in touch",
    close: "Close",
    reload: "Reload page",
    header: {
      0: "Value your property for free",
      1: "Characteristics",
      2: "Personal data",
      3: "Result",
      //3: "SMS Validation",
    },
    step: "Step",
    of: "de",
    continue: "CONTINUE",
    submit: "SUBMIT",
    confirm: "CONFIRM",
    invalidLatLng: "Input address must be in Portugal",
    interestedIn: "Interested in:",
    enterCode: "Enter code sent to",
    labels: {
      email: "Your email address",
      assetType: "Asset type",
      apartment: "Apartment",
      house: "House",
      room: "Room",
      building: "Building",
      office: "Office",
      store: "Store",
      warehouse: "Warehouse",
      hotel: "Hotel",
      hostel: "Hostel",
      mansion: "Mansion",
      palace: "Palace",
      homestead: "Homestead",
      commercial: "Commercial",
      storageRoom: "Storage Room",
      parkingSpace: "Parking Space",
      land: "Land",
      urbanLand: "Urban Land",
      buildingYear: "Building year",
      condition: "Condition",
      energyEfficiency: "Energy certificate",
      grossArea: "Gross area",
      numberOfParkingSpots: "Parking spots",
      numberOfRooms: "Number of rooms",
      numberOfWcs: "Number of WCs",
      terrainArea: "Terrain area",
      usefulArea: "Useful area",
      wardrobe: "Wardrobe",
      gym: "Gym",
      airConditioning: "Air conditioning",
      alarm: "Alarm",
      cityView: "City view",
      closedGarage: "Garage",
      pool: "Pool",
      balcony: "Balcony",
      elevator: "Elevator",
      storage: "Storage Room",
      electricBlinds: "Electric blinds",
      parking: "Parking space",
      equippedKitchen: "Equipped Kitchen",
      videoSecurity: "Video surveillance",
      fireplace: "Fireplace",
      garden: "Garden",
      centralHeating: "Central Heating",
      suite: "Suite",
      pantry: "Pantry",
      doubleWindows: "Double glazed windows",
      goodLightExposure: "Sun exposure",
      basement: "Basement",
      terrace: "Terrace",
      seaView: "Sea view",
      name: "Name",
      phone: "Phone number",
      leadType: "I am",
    },
    options: {
      assetType: {
        apartment: "Apartament",
        house: "House",
      },
      condition: {
        new: "New",
        used: "Used",
        renovated: "Renovated",
        inConstruction: "New construction",
        forRecovery: "For recovery",
        ruin: "Ruin",
        almostNew: "Almost new",
      },
      numberOfRooms: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
        13: "13",
      },
      leadType: {
        buy: "Buy",
        sell: "Sell",
        rent: "Rent",
      },
    },
  },
};

export const AUTO_COMPLETE_LOCATION = {
  pt: {
    label: "Introduza morada",
    noOptions: "Sem resultados",
  },
  en: {
    label: "Choose location",
    noOptions: "No options",
  },
};

export const PRICE_INTERVAL = {
  pt: {
    min: "Valor mínimo",
    max: "Valor máximo",
  },
  en: {
    min: "Minimum value",
    max: "Maximum value",
  },
};
