import React from "react";
import { LinearProgress, DialogTitle, IconButton, Grid } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

const Header = (props) => {
  const { step, totalSteps, performingAction, handleClose, copy, setStep } = props;

  return (
    <Grid>
      {/*     <LinearProgress
        variant="determinate"
        value={(1 / totalSteps + step / totalSteps) * 100}
        style={{ height: "3px" }}
      /> */}
      <DialogTitleCustom>
        <Grid container alignItems="center">
          <Grid item>
            {step !== 0 &&
              step !== totalSteps && ( //FIXME needs to be adapted to SMS Validation
                <IconButton
                  onClick={() => setStep(step - 1)}
                  disabled={performingAction}
                  edge="start"
                >
                  <ArrowBack />
                </IconButton>
              )}
          </Grid>
          <Grid item xs>
            <Section>
              <Grid container>
                <Grid item xs={12} className="title">
                  {copy.header[step]}
                </Grid>
                <Grid item className="steps-text">
                  {copy.step + " " + (step + 1) + " " + copy.of + " " + (totalSteps + 1)}
                </Grid>
              </Grid>
            </Section>
          </Grid>
          <Grid item>
            {/*   <IconButton onClick={handleClose} disabled={performingAction}>
              <CloseIcon />
            </IconButton> */}
          </Grid>
        </Grid>
      </DialogTitleCustom>
    </Grid>
  );
};

const DialogTitleCustom = styled(DialogTitle)`
  //border-bottom: 1px solid rgb(235, 235, 235);
  padding: 10px;

  .title {
    color: #000;
  }
  .steps-text {
    color: #000;
  }
`;

const Section = styled.section`
  .title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
  }

  .steps-text {
    font-size: 14px;
  }
`;

export default Header;
