/*
 fields = {
   value: "",
   fieldMetada:{
    type:"string",
    min:int/float,
    max:int/float,
    required: "boolean"
   }
 }
*/

const validateField = (fieldMetada, value) => {
  const isRequired = fieldMetada.required && checkRequired(value);
  if (isRequired && isRequired.error) return isRequired;
  switch (fieldMetada.type) {
    case "email":
      return validateEmail(value);
    case "phone":
      return validatePhone(value);
    case "postalCode":
      return validatePostCode(value);
    case "number":
      return checkFloat(value, fieldMetada.min, fieldMetada.max);
    default:
      return validateText(value);
  }
};

const checkRequired = (value, error = false, helperText = " ") => {
  if (
    !value ||
    //value.replace(/\s/g, "") === "" ||
    value === "" ||
    (Array.isArray(value) && value.length === 0)
  ) {
    error = true;
    helperText = "Campo obrigatório";
  } else if (typeof value === "string" && value.replace(/\s/g, "") === "") {
    error = true;
    helperText = "Campo obrigatório";
  }
  return { value, error, helperText };
};

const checkFloat = (value, min = null, max = null, error = false, helperText = " ") => {
  if (typeof value !== "number" && isNaN(value.replace(",", ".").replace(/\s/g, ""))) {
    error = true;
    helperText = "Valor inválido";
  } else if (min && min > value) {
    error = true;
    helperText = `O valor introduzido tem que ser superior a ${min}`;
  } else if (max && max < value) {
    error = true;
    helperText = `O valor introduzido tem que ser inferior a ${max}`;
  }
  return { value, error, helperText };
};

const validateEmail = (value, error = false, helperText = " ") => {
  const REGEX_EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!REGEX_EMAIL.test(value)) {
    error = true;
    helperText = "E-mail inválido";
  }
  return { value: value.trim().toLowerCase(), error, helperText };
};

const validatePhone = (value, error = false, helperText = " ") => {
  const REGEX_PHONE = /^$|(?:2[0123456789]|9[1236])[0-9]{7}$/;
  if (!REGEX_PHONE.test(value)) {
    error = true;
    helperText = "Número inválido";
  }
  return { value, error, helperText };
};

const validatePostCode = (value, error = false, helperText = " ") => {
  const REGEX_POSTCODE = /\d{4}(-\d{3})?/;
  if (!REGEX_POSTCODE.test(value)) {
    error = true;
    helperText = "Código postal inválido.";
  }
  return { value, error, helperText };
};

const validateText = (value, error = false, helperText = " ") => ({
  value,
  error,
  helperText,
});

export { validateField, checkRequired, validateEmail, checkFloat };
