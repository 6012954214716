import React, { Component } from "react";
import ReactDom from "react-dom";
import { Button, DialogActions } from "@material-ui/core";
import { isDataURL } from "./utils";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import Avatar from "@material-ui/core/Avatar";
import initials from "initials";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slider from "@material-ui/core/Slider";

import Badge from "@material-ui/core/Badge";
import AddPhoto from "@material-ui/icons/AddPhotoAlternateOutlined";
import IconButton from "@material-ui/core/IconButton";

import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";

class AvatarPreviewComponent extends React.Component {
  constructor() {
    super();

    // getInitialState
    this.state = {
      uploadingAvatar: false,
      dragging: false,
      image: {},
      mouse: {
        x: null,
        y: null,
      },
      preview: null,
      zoom: 1,
    };

    this.listeners = [];
  }

  fitImageToCanvas(width, height) {
    var scaledHeight, scaledWidth;

    var canvasAspectRatio = this.props.height / this.props.width;
    var imageAspectRatio = height / width;

    if (canvasAspectRatio > imageAspectRatio) {
      scaledHeight = this.props.height;
      let scaleRatio = scaledHeight / height;
      scaledWidth = width * scaleRatio;
    } else {
      scaledWidth = this.props.width;
      let scaleRatio = scaledWidth / width;
      scaledHeight = height * scaleRatio;
    }

    return { width: scaledWidth, height: scaledHeight };
  }

  prepareImage(imageUri) {
    var img = new Image();
    if (!isDataURL(imageUri)) img.crossOrigin = "anonymous";
    img.onload = () => {
      var scaledImage = this.fitImageToCanvas(img.width, img.height);
      scaledImage.resource = img;
      scaledImage.x = 0;
      scaledImage.y = 0;
      this.setState({
        dragging: false,
        image: scaledImage,
        preview: this.toDataURL(),
      });
    };
    img.src = imageUri;
  }

  mouseDownListener(e) {
    this.setState({
      image: this.state.image,
      dragging: true,
      mouse: {
        x: null,
        y: null,
      },
    });
  }

  preventSelection(e) {
    if (this.state.dragging) {
      e.preventDefault();
      return false;
    }
  }

  mouseUpListener(e) {
    this.setState({ dragging: false, preview: this.toDataURL() });
  }

  mouseMoveListener(e) {
    if (!this.state.dragging) return;

    var mouseX = e.clientX;
    var mouseY = e.clientY;
    var imageX = this.state.image.x;
    var imageY = this.state.image.y;

    var newImage = this.state.image;

    if (this.state.mouse.x && this.state.mouse.y) {
      var dx = this.state.mouse.x - mouseX;
      var dy = this.state.mouse.y - mouseY;

      var bounded = this.boundedCoords(imageX, imageY, dx, dy);

      newImage.x = bounded.x;
      newImage.y = bounded.y;
    }

    this.setState({
      image: this.state.image,
      mouse: {
        x: mouseX,
        y: mouseY,
      },
    });
  }

  boundedCoords(x, y, dx, dy) {
    var newX = x - dx;
    var newY = y - dy;

    var scaledWidth = this.state.image.width * this.state.zoom;
    var dw = (scaledWidth - this.state.image.width) / 2;

    var rightEdge = this.props.width;

    if (newX - dw > 0) {
      x = dw;
    } else if (newX < -scaledWidth + rightEdge) {
      x = rightEdge - scaledWidth;
    } else {
      x = newX;
    }

    var scaledHeight = this.state.image.height * this.state.zoom;
    var dh = (scaledHeight - this.state.image.height) / 2;

    var bottomEdge = this.props.height;
    if (newY - dh > 0) {
      y = dh;
    } else if (newY < -scaledHeight + bottomEdge) {
      y = bottomEdge - scaledHeight;
    } else {
      y = newY;
    }

    return { x: x, y: y };
  }

  componentDidMount() {
    var canvas = ReactDom.findDOMNode(this.refs.canvas);
    this.prepareImage(this.props.image);

    this.listeners = {
      mousemove: (e) => this.mouseMoveListener(e),
      mouseup: (e) => this.mouseUpListener(e),
      mousedown: (e) => this.mouseDownListener(e),
    };

    window.addEventListener("mousemove", this.listeners.mousemove, false);
    window.addEventListener("mouseup", this.listeners.mouseup, false);
    canvas.addEventListener("mousedown", this.listeners.mousedown, false);
    document.onselectstart = (e) => this.preventSelection(e);
  }

  componentWillUnmount() {
    var canvas = ReactDom.findDOMNode(this.refs.canvas);
    window.removeEventListener("mousemove", this.listeners.mousemove);
    window.removeEventListener("mouseup", this.listeners.mouseup);
    canvas.removeEventListener("mousedown", this.listeners.mousedown);
  }

  componentDidUpdate() {
    var context = ReactDom.findDOMNode(this.refs.canvas).getContext("2d");
    context.clearRect(0, 0, this.props.width, this.props.height);
    this.addImageToCanvas(context, this.state.image);
  }

  addImageToCanvas(context, image) {
    if (!image.resource) return;
    context.save();
    context.globalCompositeOperation = "destination-over";
    var scaledWidth = this.state.image.width * this.state.zoom;
    var scaledHeight = this.state.image.height * this.state.zoom;

    var x = image.x - (scaledWidth - this.state.image.width) / 2;
    var y = image.y - (scaledHeight - this.state.image.height) / 2;

    // need to make sure we aren't going out of bounds here...
    x = Math.min(x, 0);
    y = Math.min(y, 0);
    y = scaledHeight + y >= this.props.height ? y : y + (this.props.height - (scaledHeight + y));
    x = scaledWidth + x >= this.props.width ? x : x + (this.props.width - (scaledWidth + x));

    context.drawImage(
      image.resource,
      x,
      y,
      image.width * this.state.zoom,
      image.height * this.state.zoom
    );
    context.restore();
  }

  toDataURL() {
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");

    canvas.width = this.props.width;
    canvas.height = this.props.height;

    this.addImageToCanvas(context, {
      resource: this.state.image.resource,
      x: this.state.image.x,
      y: this.state.image.y,
      height: this.state.image.height,
      width: this.state.image.width,
    });

    return canvas.toDataURL();
  }

  handleSave() {
    var data = this.toDataURL();
    this.props.onSave(data);
  }

  handleZoomUpdate() {
    var newstate = this.state;
    newstate.zoom = ReactDom.findDOMNode(this.refs.zoom).value;
    this.setState({ newstate });
  }

  handleSliderChange = (event, newValue) => {
    this.setState({
      zoom: newValue + 1,
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} style={{ textAlign: "center", paddingBottom: 25 }}>
          <canvas
            ref="canvas"
            width={this.props.width}
            height={this.props.height}
            style={{
              background: this.props.previewBackgroundColor,
              // borderRadius: "100%",
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Slider
            min={0}
            max={1.99}
            step={0.01}
            onChange={this.handleSliderChange}
            defaultValue={0}
            style={{ width: "50%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <DialogActions>
              <Grid item>
                <Button onClick={this.props.onRequestHide} className={classes.customButton}>
                  {this.props.closeButtonText}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={this.handleSave.bind(this)}
                  className={classes.customButton}
                >
                  {this.props.saveButtonText}
                </Button>
              </Grid>
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

class AvatarPicker extends React.Component {
  render() {
    return (
      <Dialog
        open={this.props.previewOpen}
        // onHide={this.props.onRequestHide}
        maxWidth={"xs"}
        fullScreen={window.innerWidth < 768 ? true : false}
      >
        <Grid container justify="center" alignItems="center">
          <DialogTitle>Imagem de Perfil</DialogTitle>
          <DialogContent>
            <AvatarPreview
              image={this.props.image}
              width={this.props.width}
              height={this.props.height}
              onSave={this.props.onSave}
              onRequestHide={this.props.onRequestHide}
              closeButtonText={this.props.closeButtonText}
              saveButtonText={this.props.saveButtonText}
              //previewBackgroundColor={this.props.previewBackgroundColor}
            />
          </DialogContent>
        </Grid>
      </Dialog>
    );
  }
}

class AvatarImageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewOpen: false,
      img: null,
      savedImg: props.src,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.src !== this.props.src) {
      this.setState({
        savedImg: this.props.src,
      });
    }
  }

  componentDidMount = () => {};

  handleFileChange = (dataURI) => {
    this.setState({
      img: dataURI,
      savedImg: this.state.savedImg,
      previewOpen: true,
    });
  };

  handleSave = (dataURI) => {
    const { onSaveImage } = this.props;
    const base64Image = dataURI.replace("data:image/png;base64,", "");
    this.setState(
      {
        previewOpen: false,
        uploadingAvatar: true,
        //savedImg: null
      },
      () =>
        onSaveImage(base64Image).then((resolve) =>
          this.setState({
            img: null,
            uploadingAvatar: false,
            savedImg: resolve.newUser ? dataURI : resolve.image,
          })
        )
    );
  };

  handleRequestHide = () => {
    this.setState({
      previewOpen: false,
    });
  };

  handleFile = (e) => {
    if (!e) {
      return;
    }

    var reader = new FileReader();
    var file = e.target.files[0];

    if (!file) return;

    const fileTypes = [
      "image/gif",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/webp",
      "image/svg+xml",
    ];

    if (!fileTypes.includes(file.type)) {
      return;
    }

    /* const validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
    if (!validImageTypes.includes(file.type)) {
      return this.handleRequestHide();
    } */

    reader.onload = function (img) {
      ReactDom.findDOMNode(this.fileInput).value = "";
      this.handleFileChange(img.target.result);
    }.bind(this);
    reader.readAsDataURL(file);
  };

  triggerUpload = () => {
    this.fileInput.click();
  };

  formatName = (name) => {
    const splittedName = name.trim().split(" ");
    let nameToShow;
    if (splittedName.length > 2)
      nameToShow = `${splittedName[0].toLowerCase()} ${splittedName[
        splittedName.length - 1
      ].toLowerCase()}`;
    else nameToShow = name.toLowerCase();
    return initials(nameToShow).toUpperCase();
  };

  render() {
    const { savedImg, uploadingAvatar } = this.state;
    const {
      classes,
      allowImageUpload,
      name,
      width,
      height,
      badgeWidth,
      badgeHeight,
      fontSize,
      badge,
    } = this.props;

    const abbr = name ? this.formatName(name) : null;

    return [
      <div key="avatar">
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={
            allowImageUpload && (
              <Avatar
                onClick={!badge && this.triggerUpload}
                style={{
                  border: `2px solid white`,
                  width: badgeWidth || width * 0.4,
                  height: badgeHeight || height * 0.4,
                  background: "#f2f2f2",
                }}
              >
                <IconButton aria-label="view-list">
                  <AddPhoto style={{ fontSize: width * 0.2, color: "#009de0" }} />
                </IconButton>
              </Avatar>
            )
          }
        >
          {uploadingAvatar && allowImageUpload ? (
            <Badge
              classes={{ badge: classes.loadingBadge }}
              badgeContent={
                <Fade style={{ transitionDelay: "1s" }} in={uploadingAvatar} unmountOnExit>
                  <CircularProgress size={120} thickness={1.8} />
                </Fade>
              }
            >
              <Avatar
                className={classes.avatar}
                alt="Avatar"
                src={savedImg ? savedImg : ""}
                style={{
                  width: width,
                  height: height,
                  fontSize: fontSize || width * 0.3,
                  fontWeight: 700,
                }}
              >
                {!savedImg && abbr}
              </Avatar>
            </Badge>
          ) : (
            <Avatar
              className={classes.avatar}
              alt="Avatar"
              src={savedImg ? savedImg : ""}
              style={{
                width: width,
                height: height,
                fontSize: fontSize || width * 0.3,
                fontWeight: 700,
                ...this.props.style,
              }}
            >
              {!savedImg && abbr}
            </Avatar>
          )}
        </Badge>
        <input
          onChange={this.handleFile}
          disabled={!allowImageUpload}
          ref={(fileInput) => (this.fileInput = fileInput)}
          type="file"
          accept={"image/*"}
          style={{ display: "none" }}
        />
      </div>,
      this.state.previewOpen && (
        <AvatarPicker
          key="avatar-picker"
          onRequestHide={this.handleRequestHide}
          previewOpen={this.state.previewOpen}
          onSave={this.handleSave}
          image={this.state.img}
          width={300}
          height={300}
          closeButtonText={"CANCELAR"}
          saveButtonText={"GRAVAR"}
        />
      ),
    ];
  }
}

const AvatarImage = withStyles(styles)(AvatarImageComponent);
const AvatarPreview = withStyles(styles)(AvatarPreviewComponent);

export { AvatarImage };
