import React from "react";
import { PRICE_INTERVAL } from "../../data/copy";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

const PriceInterval = (props) => {
  const { predict, lang, color } = props;

  const copy = PRICE_INTERVAL[lang];
  const medianOne = predict.avg.gross;
  const medianTwo = predict.avg.perSq;

  function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");

      return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.3)";
    }
    throw new Error("Bad Hex");
  }

  return (
    <Container container gradientColor={hexToRgbA(color)}>
      <Grid item xs={12}>
        <Grid item xs={12} className="line-one">
          {parseInt(medianOne).toLocaleString("en").replace(/,/g, " ")} €
        </Grid>

        <Grid item xs={12} className="line-two">
          {parseInt(medianTwo).toLocaleString("en").replace(/,/g, " ")} €/m²
        </Grid>

        <Grid
          container
          // className={classes.lineThree}
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs className="line-three-left">
            {copy.min}
          </Grid>
          <svg className="svg-polygon">
            <polygon points="0,0 10,12 20,0" className="polygon" />
          </svg>
          <Grid item xs className="line-three-right">
            {copy.max}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <hr className="line-four" />
        </Grid>

        <Grid container className="line-five">
          <Grid item xs={4} className="line-five-left">
            {parseInt(predict.min.gross).toLocaleString("en").replace(/,/g, " ")} €
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} className="line-five-right">
            {parseInt(predict.max.gross).toLocaleString("en").replace(/,/g, " ")} €
          </Grid>
        </Grid>

        <Grid container className="line-six">
          <Grid item xs={4} className="line-six-left">
            {predict.min.perSq.toFixed(0).toLocaleString("en").replace(/,/g, " ")} €/m²
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} className="line-six-right">
            {predict.max.perSq.toFixed(0).toLocaleString("en").replace(/,/g, " ")} €/m²
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const Container = styled(Grid)`
  .line-one {
    letter-spacing: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    font-weight: bold;
    line-height: 0.83;
    font-size: 52px;
    color: #1c1c1c;
  }

  .line-two {
    letter-spacing: normal;
    font-stretch: normal;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    line-height: 2.5;
    font-size: 12px;
    color: #9e9d9d;
  }

  .line-three-center {
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #00fff6;
    height: 0px;
    width: 0px;
  }

  .line-three-left {
    letter-spacing: normal;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    font-size: 12px;
    line-height: 3;
    color: #1c1c1c;
  }

  .line-three-right {
    letter-spacing: normal;
    font-stretch: normal;
    font-weight: 600;
    font-style: normal;
    text-align: right;
    line-height: 3px;
    font-size: 12px;
    color: #1c1c1c;
  }

  .svg-polygon {
    height: 15px;
    width: 20px;
  }

  .polygon {
    fill: var(--color-primary);
  }

  .line-four {
    background-image: linear-gradient(to left, var(--color-primary), ${(p) => p.gradientColor});
    "-webkit-print-color-adjust":exact ;
    "color-adjust":exact ;
    border-radius: 10px;
    height: 10px;
    width: 100%;
    border: 0;
    margin: 0;
  }

  .line-five {
    letter-spacing: normal;
    font-stretch: normal;
    font-weight: normal;
    font-style: normal;
    line-height: 2.45;
    font-size: 14px;
    color: #1c1c1c;
  }

  .line-five-left {
    padding-bottom: 5px;
    text-align: left;
    font-weight: 700;
  }

  .line-five-right {
    padding-bottom: 5px;
    text-align: right;
    font-weight: 700;
  }

  .line-six {
    letter-spacing: normal;
    font-stretch: normal;
    font-weight: normal;
    font-style: normal;
    line-height: 0px;
    font-size: 12px;
    color: #9e9d9d;
  }

  .line-six-left {
    text-align: left;
  }

  .line-six-right {
    text-align: right;
  }

  .price {
    text-align: center;
    line-height: 66px;
    font-weight: 900;
    font-size: 52px;
    color: #484848;
    height: 66px;
  }
`;

export default PriceInterval;
