import React, { useState, useEffect } from "react";
import LoadingGif from "../../assets/images/net.gif";
import { Grid } from "@material-ui/core";
import PriceInterval from "../PriceIntervalNew";
import { LEADS_FORM } from "../../data/copy";
import styled from "styled-components";
import getFields from "./fields";
import Content from "./Content";
import Header from "./Header";
import Footer from "./Footer";
import LogoAlfredo from "../../assets/images/alfredo/IST_C_POS_S.png";

const LeadsModule = (props) => {
  const { lang, mobile, token, userID, color, smsValidation = false } = props;

  const copy = LEADS_FORM[lang];
  const INITIAL_STEP = 0;

  const [performingAction, setPerformingAction] = useState(false);
  const [totalSteps, setTotalSteps] = useState(0);
  const [step, setStep] = useState(INITIAL_STEP);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState({});

  const [leadID, setLeadID] = useState(null);

  const [submited, setSubmited] = useState({
    status: false,
    success: false,
    predict: {
      avg: { gross: 85681.44091173084, perSq: 428.4072045586542 },
      max: { gross: 97297.7748716613, perSq: 486.4888743583065 },
      min: { gross: 74065.10695180036, perSq: 370.3255347590018 },
    },
  });

  useEffect(() => {
    const initialFields = getFields(smsValidation);

    setSubmited({
      status: false,
      success: false,
      predict: {},
    });
    setLeadID(null);

    setTotalSteps(initialFields.length);
    setFields({ ...initialFields });
    setStep(INITIAL_STEP);
    setLoading(false);
  }, []);

  const renderLoading = () => (
    <LoadingGifCustom>
      <Grid container justify="center" className="loading-gif-container">
        <img src={LoadingGif} alt="loading" className="loading-gif" height="300" />
      </Grid>
    </LoadingGifCustom>
  );

  const renderResult = () => (
    <ResultsContainer container alignItems="center" id="lead-success">
      <Grid item className="title">
        {copy.estimates}:
      </Grid>
      <PriceInterval predict={submited.predict} lang={lang} color={color} />
      {/*  <Grid item className="subtitle">
        {copy.soon}
      </Grid> */}
      <Grid container alignItems="center">
        <Grid item xs={5}>
          Powered by:
        </Grid>
        <Grid item xs={7}>
          <img src={LogoAlfredo} width="100%"></img>
        </Grid>
      </Grid>
    </ResultsContainer>
  );

  const renderFail = () => (
    <ResultsContainer container alignItems="center">
      <Grid item xs={12} className="title-error">
        Ups...
      </Grid>
      <Grid item xs={12}>
        {copy.fail}
      </Grid>
    </ResultsContainer>
  );

  return (
    <Grid container>
      {/* <button onClick={() => setLoading(!loading)}>asd</button> */}
      {!loading && (
        <Grid item xs={12}>
          <Header
            performingAction={performingAction}
            handleClose={() => console.log("sd")}
            totalSteps={totalSteps}
            setStep={setStep}
            step={step}
            copy={copy}
          />
          {submited.status ? (
            submited.success ? (
              renderResult()
            ) : (
              renderFail()
            )
          ) : (
            <Content
              totalSteps={totalSteps}
              setFields={setFields}
              fields={fields}
              step={step}
              copy={copy}
              lang={lang}
            />
          )}

          <Footer
            setPerformingAction={setPerformingAction}
            performingAction={performingAction}
            smsValidation={smsValidation}
            setSubmited={setSubmited}
            //handleClose={() => console.log("close")}
            setLoading={setLoading}
            setFields={setFields}
            setLeadID={setLeadID}
            submited={submited}
            setStep={setStep}
            fields={fields}
            mobile={mobile}
            leadID={leadID}
            userID={userID}
            token={token}
            step={step}
            copy={copy}
          />
        </Grid>
      )}
      {loading && renderLoading()}
    </Grid>
  );
};

const LoadingGifCustom = styled(Grid)`
  width: 95vw;

  .loading-gif-container {
    padding: 175px 0;
    @media (max-width: 600px) {
      margin-top: 10%;
    }
  }

  .loading-gif {
    height: 180px;
  }
`;

const ResultsContainer = styled(Grid)`
  padding: 0px 15px;
  min-height: 435px;

  //@media (max-width: 600px) {
  //  padding: 0px 25px;
  // }

  .title {
    text-align: left;
    // font-size: 20px;
    line-height: 1;
    color: #000;
  }

  .title-error {
    font-size: 5rem;
    font-weight: 700;
    @media (max-width: 600px) {
      font-size: 6rem;
    }
  }

  .alfredo-logo {
    text-align: right;
    padding-top: 30px;
  }
`;

export default LeadsModule;
