import React from "react";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { AUTO_COMPLETE_LOCATION } from "../../data/copy";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const AutoCompleteLocation = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  const { lang } = props;
  const copy = AUTO_COMPLETE_LOCATION[lang];

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        //        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAd2DHrkiYuQ3rWLMDN_7c7reJSgt_JoDc&libraries=places",
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAmFVxkjuJggwqq2Sqz3915bK5fXAdl9cI&libraries=places", // only for dev
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(
          { ...request, componentRestrictions: { country: "pt" } },
          callback
        );
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions];
          results.map((r) => {
            if (r.matched_substrings.length > 0) {
              newOptions.push(r);
            }
          });
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  React.useEffect(() => {
    if (props.address !== value) {
      setValue(props.address);
    }
  }, [props.address]);

  const { error, helperText } = props;

  return (
    <Autocomplete
      id="new-valuation-address"
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (props.onChange) {
          props.onChange(newValue ? newValue.description : null);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      noOptionsText={copy.noOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label={copy.label}
          variant="outlined"
          fullWidth
          error={error}
          helperText={helperText}
          // style={{ textColor: "black" }}
        />
      )}
      renderOption={(option) => {
        if (!option.structured_formatting) return;
        const matches = option.structured_formatting.main_text_matched_substrings;
        if (!matches) return;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default AutoCompleteLocation;
